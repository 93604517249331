<template>
  <div class="gpu-list">
    <div class="sub-banner-box">
      <sub-banner :title="banner.title" :subTitle="banner.subTitle" :bannerBg="banner.bannerBg"></sub-banner>
      <!--<i class="back" @click="handleBack"></i>-->
    </div>
    <div class="content">
      <div class="title">
        <hgroup>
          <h3>GPU列表</h3>
          <h5>共{{GPUList.length}}条</h5>
        </hgroup>
        <p @click="handleBack">返回</p>
      </div>
      <ul class="gpu-list-ul" v-if="GPUList.length > 0">
        <li class="li-header">
          <h4 class="flex-1">列表序号</h4>
          <h4 class="flex-3">GPU编号</h4>
          <h4 class="flex-2">GPU型号</h4>
        </li>
        <li v-for="(item, index) of GPUList"
            :key="item.gpuId"
            class="li-body van-hairline--bottom"
        >
          <p class="flex-1">{{index+1}}</p>
          <p class="flex-3">{{item.gpuId}}</p>
          <p class="flex-2">{{item.gpuType}}</p>
        </li>
      </ul>
      <van-empty v-else description="暂无数据" />
    </div>
  </div>
</template>

<script>
import subBanner from '@/components/subBanner'
import { mapGetters } from 'vuex'
import pageMixin from '@/mixins/pageMixin'
export default {
  name: 'GPUList',
  mixins: [pageMixin],
  components: {
    subBanner
  },
  data () {
    return {
      userOfferId: '',
      GPUList: [],
      banner: {
        title: '个人中心',
        subTitle: 'Personal center',
        bannerBg: require('@/assets/images/user/banner.jpg')
      }
    }
  },
  computed: {
    ...mapGetters(['userInfo'])
  },
  watch: {
    '$route' () {
      this.judgeId()
    }
  },
  created () {
    this.judgeId()
  },
  async mounted () {
  },
  methods: {
    judgeId () {
      const query = this.$route.query
      if (JSON.stringify(query.key) === {} || !query.key) {
        this.$router.push('/user/index')
      } else {
        this.userOfferId = query.key
        this.fetchGPUList()
      }
    },
    async fetchGPUList () {
      try {
        this.GPUList = await this.api.fetchGpus(this.userOfferId)
      } catch (e) {
        console.log(e)
      } finally {
        this.loading = false
      }
    },
    handleBack () {
      this.$router.back()
    }
  }
}
</script>

<style lang="less" scoped>
  .gpu-list{
    .sub-banner-box{
      position: relative;
      .back{
        position: absolute;
        top: @header-height + 20;
        left: 30px;
        display: block;
        width: 32px;
        height: 32px;
        background-image: url("~@/assets/images/icon_back.png");
        .full_bg;
      }
    }
    .content{
      padding: 50px 30px;
      font-size: 26px;
      .title{
        .flex(@justify: space-between);
        margin-bottom: 20px;
        hgroup{
          padding: 0 12px;
          h3{
            color: @black;
            font-size: 36px;
            margin-bottom: 20px;
          }
          h5{
            color: @grey;
          }
        }
        p{
          color: @blue;
          padding-left: 20px;
        }
      }
      .gpu-list-ul{
        li{
          .flex(@justify: flex-start);
          padding: 0 30px;
          color: @grey2;
          text-align: center;
          h4, p{
            flex: 1;
            &.flex-1{
              flex: 1;
            }
            &.flex-2{
              flex: 2;
            }
            &.flex-3{
              flex: 3;
            }
          }
          p{
            line-height: 30px;
          }
          &.li-header{
            height: 78px;
            background: #F5F9FF;
          }
          &.li-body{
            padding: 20px 30px;
          }
        }
      }
    }
  }
</style>
